import { LOAD, RATING } from "./Actions";

  export const reducer = (state = [],action) => {
    switch (action.type) {
      case LOAD:
        return action.payload;

      case RATING:
          return action.payload;
          
      default:
        return state;
    }
  };
