import React, { useState } from 'react'
import './About.scss';
import FbLoader from './FbLoader';
// import { useDispatch, useSelector } from "react-redux";
// import { RATING } from "../redux/Actions";


const StarRating = () => {
  // // const rating = useSelector((state) => state);
  // const dispatch = useDispatch();
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [showThanks, setShowThanks] = useState(false);
  const [loading, setLoading] = useState(false)
  const Stars = () =>{
    return(
      <div className="star-rating">
        {loading ? <FbLoader/>: 
          <div>
            <p className='text-center m-0 p-0'>Rate your Experience</p>
            {[...Array(5)].map((star, index) => {
              index += 1;
              return (
                <button
                  type="button"
                  key={index}
                  className={index <= (hover || rating) ? "on" : "off"}
                  onClick={() => {setRating(index);setLoading(true);setTimeout(()=>{setShowThanks(!showThanks);setLoading(false)},2000)}}
                  onMouseEnter={() => setHover(index)}
                  onMouseLeave={() => setHover(rating)}
                  onDoubleClick={() => {setRating(index);setHover(0)}}
                >
                  <span className="star fs-1">&#9733;</span>
                </button>
          );
          })}
          </div>}
      </div>
    )
  }

return (
  <div className='d-flex justify-content-center my-3'>
    {showThanks ? <h5>Thanks for your FeedBack - {rating}/5 ❤️</h5> : <Stars />}
  </div>
  )
}

export default StarRating