import { Route, Routes } from 'react-router';
import './App.scss';
import NavBar from './components/NavBar';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Footer from './components/Footer';


function App() {


  return (
    <div>
      <NavBar/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path="/#feedBack" element={<Footer/>} />
      </Routes>
    </div>
  );
}

export default App;
