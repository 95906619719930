import React from 'react';
import './FbLoader.scss';


const FbLoader = () => {
  return (
    <div className='loader'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
  )
}

export default FbLoader