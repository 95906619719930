import React from 'react'
import {Card} from 'react-bootstrap'
import './Home.scss'
import Details from './Details';
import SliderComp from './SliderComp';
import Footer from './Footer';
const Home = () => {

  return (
    <div>
      <Card className="border-0 rounded-0 text-white outer">
        {/* <Card.Img src="Images/bg.jpg" alt="Card image" className='w-100 img-fluid img rounded-0'/> */}
        <Card.ImgOverlay className='text-center overlay'>
          <Card.Title className='title'>
            Welcome to <span>Isai</span><span>V</span><span>i</span><span>d</span><span>s</span>
          </Card.Title>
          <Card.Text className='fs-4 my-3 text-white'>
            Let's dive into the world of music videos together !!
          </Card.Text>
          <div className='d-flex justify-content-center gap-3 py-3'>
            <a href="https://www.youtube.com/c/IsaiVids/featured/" 
                rel="noopener noreferrer" 
                target="_blank" 
                className='btn btn-lg btn-light rounded-0 fw-bold'>
                  Explore
            </a>
            <a href='#feedBack' rel="noopener noreferrer"
                className='btn btn-lg btn-primary rounded-0 fw-bold'>
                FeedBack
            </a>
          </div>
        </Card.ImgOverlay>
      </Card>
      <Details/>
      <SliderComp />
      <Footer />
    </div>
  )
}

export default Home