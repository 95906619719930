import React from 'react'
import './Details.scss';
import { Button, Card,Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faHashtag, faNetworkWired } from '@fortawesome/free-solid-svg-icons'
import smData from '../json/Cards.json'

const Details = () => {

  return (
      <div className='bg-light socialMedia'>
      <Row className='text-center text-secondary m-0'>
        <h4>Our social media pages & Works !!!</h4>
      </Row>
      <Row className='m-2 d-flex justify-content-evenly m-0'>
        {smData && smData.map((smd)=>{
          return(
            <Card key={smd.id} style={{ width: '19rem'}} className="p-3 my-3 border-0 cardStyle">
              <Card.Body className=''>
                <Card.Title>
                  <div className='logoWrapper'>
                    <img src={smd.icon} alt="logo" />
                  </div>
                </Card.Title>
                <Card.Title className='fs-2 text-center'>{smd.count}</Card.Title>
                <Card.Text className='text-center'>{smd.sm}</Card.Text>
                <Button variant={smd.type === "YouTube" ? "danger" : smd.type === "Instagram" ?  "primary text-white" : "secondary"}
                  className="reLink d-block m-auto"
                  onClick={() => {
                    window.open(`${smd.url}`, "_blank");
                  }}
                >
                  {smd.type === "YouTube" ?
                    <FontAwesomeIcon icon={faBell} className="mx-2" />
                    : smd.type === "Instagram" ? 
                    <FontAwesomeIcon icon={faHashtag} className="mx-2" /> 
                    : <FontAwesomeIcon icon={faNetworkWired} className="mx-2" />
                  }
                {smd.btn}</Button>
              </Card.Body>
          </Card>
          )
        }) }
      </Row>
      </div>
  )
}

export default Details