import React, { useState,useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faYoutube, faInstagram, faGithub, faTwitter} from '@fortawesome/free-brands-svg-icons'
import './Footer.scss'
import emailjs from '@emailjs/browser';
import { Toast } from 'react-bootstrap';

const Footer = () => {
const initialValues = { name: '', email: ''};
const [input, setInput] = useState(initialValues);
const [time, setTime] = useState('');
const ipref1 = useRef();
const ipref2 = useRef();
const [showA, setShowA] = useState(false);
const toggleShowA = () => setShowA(!showA);


const handleChange = (e) => {
  const { name, value } = e.target;
  setInput({ ...input, [name]: value });
};


const handleSubmit = (e) => {
  e.preventDefault();
  setInput(input);
    emailjs.sendForm('service_vg3512t', 'template_yo54y92', e.target,  'd3O38lCYfhfkrt6nG')
    .then(function(response) {
      //  console.log('SUCCESS!', response.status, response.text);
       toggleShowA();
       ipref1.current.value = ''
       setInput(initialValues);
       var today = new Date();
       setTime(today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds());
    }, function(error) {
       alert("Unable to send Feedback now")
      //  console.log('FAILED...', error);
       ipref1.current.value = ''
       setInput(initialValues)
    });
};

const PopToast = () =>{
  return(
    <div className='tst'>
      <Toast show={showA} onClose={toggleShowA} delay={3000} autohide className='toast'>
      <Toast.Header>
        <img
          src="iLogo.jpg"
          className="rounded me-2"
          alt=""
          style={{width: 20 }}
        />
        <strong className="me-auto">Isaivids</strong>
        <small>{time}</small>
      </Toast.Header>
      <Toast.Body className='text-white'>Feedback shared !! Thanks</Toast.Body>
    </Toast>
  </div>
  )
}


  return (
    <div className='text-center bg-dark m-0 py-4 text-white footer' id="feedBack">
          <form className='p-0 m-0 d-flex justify-content-center flex-wrap' onSubmit={handleSubmit}>
              <input 
                required
                type='text' 
                className='rounded-0 mx-2 px-2 border-0' 
                placeholder='Enter your Message'
                value={input.message}
                name="name"
                onChange={handleChange}
                ref={ipref1}
              />
              <input 
              required
                type='email' 
                className='rounded-0 mx-2 px-2 border-0' 
                placeholder='Enter your email'
                value={input.email}
                name="email"
                ref={ipref2}
                onChange={handleChange}
              />
              <button className="btn btn-success rounded-0" disabled={!(ipref1.current && ipref2.current)}>Send</button>
          </form>
        <div className='icons my-3'>
            <div className='fs-4 text-muted'>
              <FontAwesomeIcon icon={faInstagram} className="ic1 mx-3" onClick={() => { window.open(`https://www.instagram.com/isai.vids/?igshid=YmMyMTA2M2Y%3D`, "_blank") }}/>
              <FontAwesomeIcon icon={faYoutube} className="ic2 mx-3" onClick={() => { window.open(`https://www.youtube.com/c/IsaiVids/featured/`, "_blank") }}/>
              <FontAwesomeIcon icon={faGithub} className="ic3 mx-3" onClick={() => { window.open(`https://github.com/Isaivids`, "_blank") }}/>
              <FontAwesomeIcon icon={faTwitter} className="ic4 mx-3" onClick={() => { window.open(`https://github.com/Isaivids`, "_blank") }}/>
            </div>
        </div>
        <div className='copyright text-muted d-md-flex flex-wrap justify-content-center'>
            <p>©️ 2022 IsaiVids. </p>
            <p>Developer contact : 
            <a rel="noopener noreferrer" 
              target="_blank"  
              href="https://portfolio-dilan.netlify.app/" 
              className='text-primary'
            >
            Dilan
            </a>
            </p>
        </div>
        <PopToast className="toast"/>
    </div>
  )
}

export default Footer