// import axios from 'axios';

export const LOAD ='LOAD';
export const RATING ='RATING';

export function load(posts) {
    return{
        type: LOAD,
        payload: posts,
    }
}

export function rating(value) {
    return{
        type: RATING,
        payload: value,
    }
}


// export const FetchYtData =  () => {
//     const key ='AIzaSyDabxpF-KdWiwgvneXI1K7YNCKAlIYifGs'
//     const URL = `https://youtube.googleapis.com/youtube/v3/search?part=snippet&channelId=UCjN5ZwOUhYzN3nPLoOLGqYQ&channelType=any&maxResults=20&order=viewCount&key=${key}`
  
//     return async dispatch => {
//         try {
//             let posts = await axios.get('https://jsonplaceholder.typicode.com/posts')
//             dispatch(load(posts.data)) ;
//         }
//         catch(e){
//             console.log(e)
//         }
//     }
// }