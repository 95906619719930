import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons'
import {faYoutube, faInstagram} from '@fortawesome/free-brands-svg-icons'
import {Button, Container} from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import './NavBar.scss'

function NavBar() {

const [toggle, setToggle] = useState(false)
const [scroll, setScroll] = useState(false);

const changeNavbarColor = () =>{
   if(window.scrollY >= 150){
     setScroll(true);
   }
   else{
     setScroll(false);
     setToggle(false);
   }
};
window.addEventListener('scroll', changeNavbarColor);


  return (
    <Navbar collapseOnSelect expand="md" className={scroll ? 'bg' : 'none'} variant="dark" fixed="top">
      <Container fluid>
        <Navbar.Brand className='ms-3 col-lg-4 col-md-3'>
          <img
              src="Images/LogoH.png"
              height="50"
              alt="React Bootstrap logo"
          />
        </Navbar.Brand>
        
        <Navbar.Toggle className='border-0 shadow-none fs-3' aria-controls="nav-toggle" onClick={() => setToggle(!toggle)}>{toggle? 
          <FontAwesomeIcon icon={faClose} className='text-white'/> :
          <FontAwesomeIcon icon={faBars} className='text-white'/>}
        </Navbar.Toggle>

        <Navbar.Collapse id="nav-toggle" className={ toggle ? 'navbg col-lg-8 col-md-9' : 'none col-lg-8 col-md-9'}>
          <Nav className='col-md-5 fw-bold text-center justify-content-center'>
            <Nav.Link as={Link} href="/" className='mx-3 text-white' to="/"  onClick={() => setToggle(false)}>Home</Nav.Link>
            <Nav.Link as={Link} href="/services" className='mx-3 text-white' to="/services"  onClick={() => setToggle(false)}>Services</Nav.Link>
            <Nav.Link as={Link} href="/about" className='mx-3 text-white' to="/about" onClick={() => setToggle(false)}>About</Nav.Link>
          </Nav>
          <Nav className='col-md-7 text-center d-flex justify-content-center'>
            <Nav.Link href="#" target='_blank' onClick={() => setToggle(false)}>
              <Button className='info text-white rounded-0' onClick={() => {
                  window.open(`https://www.instagram.com/isai.vids/?igshid=YmMyMTA2M2Y%3D`, "_blank");
                }}><FontAwesomeIcon icon={faInstagram} className='mx-2'/>Instagram</Button>
            </Nav.Link>
            <Nav.Link href="#" target='_blank' onClick={() => setToggle(false)}>
              <Button variant='danger rounded-0' onClick={() => {
                  window.open(`https://www.youtube.com/c/IsaiVids/featured/`, "_blank");
                }}><FontAwesomeIcon icon={faYoutube} className='mx-2'/>YouTube</Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>










  );
}

export default NavBar;