import React from 'react'
import { Container,Row, Col, Button } from 'react-bootstrap'
import './Services.scss'
import ServicesData from '../json/Services.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowCircleRight} from '@fortawesome/free-solid-svg-icons'

const Services = () => {

  return (
    <div className='services '>
      {ServicesData && ServicesData.map((x)=>{
        return(
          <Container key={x.id} className={x.id % 2 === 0 ? "shadow-sm py-3 my-3 rounded-2 cont bg-secondary text-white" : "shadow-sm py-3 my-3 rounded-2 cont"}>
            <Row className={x.id % 2 === 0 ? "d-flex flex-row-reverse" : "d-flex"}>
              <Col md={6} className="left">
                <h4 className={x.id % 2 === 0 ? "text-white" :""}>{x.title}</h4>
                <p className='mx-3'>{x.Desc}</p>
                <div className='d-flex gap-3 my-3'>
                  <img src={x.ico1} alt="" style={{width : 40 }}/>
                  <img src={x.ico2} alt="" style={{width : 40 }}/>
                  <img src={x.ico3} alt="" style={{width : 40 }}/>
                </div>
                <Button variant={x.id % 2 === 0 ? "light rounded-0 px-4" :"primary rounded-0 px-4"} onClick={() => {
                  window.open(`${x.url}`, "_blank");
                }}>{x.btn}<FontAwesomeIcon className='ms-3' icon={faArrowCircleRight}/></Button>
              </Col>
              <Col md={6}>
                <div className='img-cont'>
                  <img src={x.image} alt={x.title}/>
                </div>
              </Col>
            </Row>
          </Container>
        )
      })}

    </div>
  )
}

export default Services