import {legacy_createStore} from 'redux';
import {reducer} from './Reducers';
// import thunk from 'redux-thunk'

const saveToLocalStorage = (state) => {
      localStorage.setItem('persistantState',JSON.stringify(state));
  }


const loadFromLocalStorage = () => {
    if (localStorage.getItem('persistantState') !== null) {
        return JSON.parse(localStorage.getItem('persistantState'))
    }
}

// // // export const store = legacy_createStore(reducer);
// // store.subscribe(() => saveToLocalStorage(store.getState()));

// //code to setup redux dev tools
// // const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = legacy_createStore(reducer,{}, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
// export default store



const store = legacy_createStore(
  reducer,loadFromLocalStorage(),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );

export default store;
store.subscribe(() => saveToLocalStorage(store.getState()));
