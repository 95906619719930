import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Slider.scss';
import { Button, Card} from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { faArrowCircleLeft, faArrowCircleRight, faPlay} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { load } from "../redux/Actions";
// import testData from '../json/test.json'
import Skeleton from './Skeleton';


let slidesToShow = 5;

const PreviousBtn = (props) => {
  const { className, onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <div className={className} onClick={onClick}>
          <FontAwesomeIcon icon={faArrowCircleLeft} className="text-danger fs-3" />
        </div>
      )}
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;
  return (
    <>
      {currentSlide !== slideCount - slidesToShow && (
        <div className={className} onClick={onClick}>
          <FontAwesomeIcon icon={faArrowCircleRight} className="text-danger fs-3" />
        </div>
      )}
    </>
  );
};


const carouselProperties = {
  prevArrow: <PreviousBtn />,
  nextArrow: <NextBtn />,
  slidesToShow: slidesToShow,
  slidesToScroll: 3,
  infinite: true,
  // slidesToScroll={3}
  responsive: [
    {
      breakpoint: 426,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 3,
        centerMode: false,
      },
    },
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
        centerMode: false,
        slidesToScroll: 2,
      },
    },
  ],
};


const SliderComp = () => {

  // const items = useSelector((state) => state);
  // const [input, setInput] = useState('');
  const [width, setWidth] = useState(window.innerWidth);
  const [errorr, setErrorr] = useState(false);
  const [loading, setLoading] = useState();
  // const [items, setItems] = useState([]);
  const items = useSelector((state) => state);
  const dispatch = useDispatch();
  const key ='AIzaSyD3Z2A-o_3ph8wdwY_uZtCDZvg5Y9d2vac'
  const URL = `https://youtube.googleapis.com/youtube/v3/search?part=snippet&channelId=UCjN5ZwOUhYzN3nPLoOLGqYQ&channelType=any&maxResults=20&order=viewCount&key=${key}`

  const fetchYtData = async () => {
    setLoading(true);
    const response = await axios
      .get(URL)
      .catch((err) => {
        setLoading(true)
        setErrorr(true);
        console.log("Err: ", err);
      });
    dispatch(load(response.data.items));
    setLoading(false)
  };

  useEffect(() => {
    fetchYtData();
  }, [URL]);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  if (width <= 426) {
    slidesToShow = 1;
  } else if (width > 426 && width <= 769) {
    slidesToShow = 3;
  } else if (width > 769 && width <= 1025) {
    slidesToShow = 4;
  } else {
    slidesToShow = 5;
  }
  return (
    <div style={{ margin: '30px' }} className='carousel'>
      <h3 className='text-center my-4'>Popular videos from IsaiVids !!</h3>
      {loading ? <Skeleton/> : 
      <Slider {...carouselProperties} className="my-3">
        {items && items.map((x) => (
          <Cards x={x} key={x.etag}/>
        ))}
      </Slider>
      }
    </div>
  );
};


const Cards = ({ x }) => {
  return (
    <Card className="img-card mx-2 text-center shadow-sm rounded-0">
      <div className='img-wrapper'>
        <img src={x.snippet.thumbnails.high.url}
          loading="lazy"
          alt='Thumbnail'
        />
      </div>
      <p className='my-3 fs-5'>{x.snippet.title.substring(0,16)}...</p>
      <p className='text-muted'>{x.snippet.publishedAt.substring(0,10)} - {x.snippet.publishedAt.substring(12,19)}</p>
      <Button 
        variant='danger w-50 m-auto d-block my-3' onClick={() => {
        window.open(`https://www.youtube.com/shorts/${x.id.videoId}`, "_blank");
      }}><FontAwesomeIcon icon={faPlay} className="mx-2"/>Watch</Button>
    </Card>
  );
};

export default SliderComp;