import React from 'react'
import './About.scss'
import StarRating from './StarRating'


const About = () => {
  return (
    <div className='abt'>
      <div className='bg-abt text-center text-white'>
        <h5 className='m-3'>Isaivids is a social media page, where you can find lots & lots of Video/Photo works for top tamil songs</h5>
        <a href='https://www.youtube.com/c/IsaiVids/featured/' rel="noreferrer" target="_blank" className='btn btn-light rounded-0'>Subscribe</a>
      </div>
      <div className='my-3 text-center'>
        <p>For any orders feel free to react : <a href='mailto:isaivids2020@gmail.com' rel="noreferrer" target="_blank">isaivids2020@gmail.com</a> </p>
      </div>
      <div className='dev text-center'>
        <p>©️ 2022 IsaiVids | Made with ❤️ by Dilan</p>
      </div>
      <StarRating />
    </div>
  )
}

export default About